<template>
  <div class="CloudBasedSolutions my_breadcrumb_1">
    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>解决方案</el-breadcrumb-item>
      <el-breadcrumb-item>WMS</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="my_div1">
         <p>云上方案</p>
      <div
        v-for="(item, index) in caseList"
        :key="index"
        class="my_div11"
        style="border-radius: 36px"
      >
        <el-row :gutter="20" v-if="(index + 1) % 2 == 1">
          <el-col :span="16">
            <div
              class="grid-content"
              style="text-align: left; padding-left: 20px"
              v-html="item.content"
            ></div>
          </el-col>
          <el-col :span="8">
            <div
              class="grid-content"
              style="background-size:100% 100% !important;background-repeat: no-repeat"
              v-lazy:background-image="item.picture"
            ></div>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="8">
            <div
              class="grid-content"
              v-lazy:background-image="item.picture"
            ></div>
          </el-col>
          <el-col :span="16">
            <div
              class="grid-content"
              style="text-align: left; padding-left: 20px"
              v-html="item.content"
            ></div>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div v-for="(list,index) in caseList" :key="index" class="my_div1">
      <h2 style="text-align: center;">{{ list.title }}</h2>
      <div style="margin: 10px 0;padding: 0 60px;" v-html="list.content"></div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      // currentDate: new Date(),
      caseList: [],
    };
  },
  created() {
    this.$http.post("WMSView").then((response) => {
      this.caseList = response.data.data;
      // console.log(this.caseList)
    });
    // axios.post('http://116.205.170.48:8081/api/ago/CompanyIntroduceList')
    //     .then(response => {
    //       this.caseList = response.data;
    //       console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {
  },
  methods: {},
};
</script>


<style scoped lang="scss">
//
.CloudBasedSolutions {
  margin-top: 0px;
  padding: 0rem 5.3rem;
  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding-bottom: 1.25rem;
    padding-top: 0rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    height: auto;
    margin: 10px;
    //margin-top: 90px;
    margin-left: 1rem;
  }
}

.el-row {
  border-radius: 36px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  //   border-radius: 36px;
  min-height: 350px;
  border-radius: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.my_div11 {
  width: 98%;
  margin-bottom: 50px;
  box-shadow: 10px 10px 10px rgba(104, 103, 103, 0.6);
}

.my_div11:hover {
  border-radius: 36px;
  /* 阴影水平向右偏移10px，垂直向下偏移10px 边缘模糊半径10px */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);
}
</style>
