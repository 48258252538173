<template>
  <div  class="body-width" v-loading="loading">
    <div>
      <div class="content-team">
        <el-carousel :interval="5000" arrow="always" width="100%" height="600px">
          <el-carousel-item v-for="(iteam, index) in caseList" :key="index">
            <div
              style="
                height: 600px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                 background-position: center;
              "
              class="swiper-img my_height"
              v-lazy:background-image="iteam.picture"

            ></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "index",

  data() {
    return {
      loading: true,
      caseList: [], //从服务器拿的数据
      newsList: [],

    };
  },
  created() {
    this.$http
      .post("list")
      .then((response) => {
        this.caseList = response.data.data;
        this.loading = false;
        // console.log(this.caseList);
      })
      .catch((erro) => {
        console.error("There was an error!", erro);
      });
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.body-width {
  margin-top: 20px;
 padding: 0 5.3rem;
}
</style>
