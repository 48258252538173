<template>
  <div class="TeamIntroduction my_breadcrumb_1">
    <el-breadcrumb separator-class="el-icon-arrow-right">
<!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
      <el-breadcrumb-item>资质荣誉</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1">
      <el-row class="my_el_row">
        <el-col
          :span="8"
          @click.native="toSub(item)"
          v-for="(item, index) in caseList"
          :key="index"
          :offset="index > 0 ? 2 : 0"
          class="my_span"
          style="width: 22.8%; height: 400px !important; margin: 1%;border-radius: 36px"
        >
          <el-card
            :body-style="{ padding: '0px', height: '100%' }"
            style="height: 100% !important;border-radius: 36px;"
          >
            <img :src="item.photo" class="image" />
            <div
              style="
                padding: 20px;
                text-align: center;
                font-size: 20px;
                margin: 0 auto;
              "
            >
              <!--              <span>{{ item.name }}</span>-->
              <p style="margin: 0 auto; font-size: 20px; padding-top: -20px">
                {{ item.introduce }}
              </p>
              <div class="bottom clearfix">
                <!--                <time class="time">{{ item.introduce }}</time>-->
                <!--                <el-button type="text" class="button">操作按钮</el-button>-->
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!--      <el-row style="margin-left: 50px">-->
      <!--        <el-col :span="8" @click.native="toSub(item)" v-for="(item, index) in caseList"-->
      <!--                :key="index"  style="width: 22%;margin: 20px;cursor: pointer;" class="">-->
      <!--          <el-card :body-style="{ padding: '0px' }">-->
      <!--            <img :src="item.photo" class="image">-->
      <!--            <div style="padding: 14px;">-->
      <!--              <span>{{ item.name }}</span>-->
      <!--              <div class="bottom clearfix">-->
      <!--                <time class="time">{{ item.introduce }}</time>-->
      <!--&lt;!&ndash;                <el-button type="text" class="button">操作按钮</el-button>&ndash;&gt;-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </el-card>-->
      <!--        </el-col>-->
      <!--      </el-row>-->

      <!--      <el-row style="margin-left: 50px;height: 500px">-->
      <!--        <el-col :span="8" v-for="(item, index) in caseList" :key="item" v-if="item.value1==1" :offset="index > 0 ? 2 : 0" style="width:840px;height:300px;margin: 20px">-->
      <!--          <el-card :body-style="{ padding: '0px' }" style="height: 300px">-->
      <!--            <img :src="item.photo" class="image" style="width: 40%;height: 300px;float: left">-->
      <!--            <div style="padding: 14px;height: 300px">-->
      <!--              <span style="padding-left: 20px">{{ item.name }}</span>-->
      <!--              <div class="bottom clearfix">-->
      <!--                <time style="padding-left: 20px" class="time">{{ item.introduce }}</time>-->
      <!--&lt;!&ndash;                <el-button type="text" class="button">操作按钮</el-button>&ndash;&gt;-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </el-card>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
    </div>
  </div>
</template>


  <script>
import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: [],
      dialogVisible: false,
    };
  },
  created() {
    this.$http.post("honorsList").then((response) => {
      this.caseList = response.data.rows;
    });
    //
    // axios.post('http://116.205.170.48:8081/api/ago/honorsList')
    //     .then(response => {
    //       this.caseList = response.data.rows;
    //       // console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  methods: {
    toSub(item) {
      // console.log(item)
      this.$router.push({ path: "/HonorsLevel", query: { item: item } });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>


  <style scoped lang="scss">
.TeamIntroduction {
  //   height: 540px;
  margin-top: 10px;
  .el-breadcrumb {
    line-height: normal !important;
    width: 95%;
    font-size: 1.25rem;
    padding-bottom: 1.25rem;
    padding-top: 1.2rem;
    /* padding-left: 3.2rem; */
    border-bottom: 1px solid #928e8e;
    position: relative;
    top: 25%;
    // left: 45px;
    left: 3.8125rem;
    // left: 155px;
  }

  .my_div1 {
    height: 650px;
    margin: 10px;
    margin-top: 90px;
    margin-left: 140px;
  }
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  //line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  height: 80% !important;
  background-size:100% 100% !important;background-repeat: no-repeat
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.my_span {
  cursor: pointer;
}

.my_span,.my_el_row {
  // background-color: #f2f2f2;
  // border: 1px solid #ccc;
  // // padding: 10px 20px;
  // cursor: pointer;
  // transition: background-color 0.3s;
  transition: width 0.5s, height 0.5s;
  border-radius: 36px;
}

.my_span:hover {
  border-radius: 36px;
  /* 阴影水平向右偏移10px，垂直向下偏移10px 边缘模糊半径10px */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);
}
</style>
