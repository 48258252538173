<template>
  <div class="CompanyIntroduction my-menu">
    <el-breadcrumb  separator-class="el-icon-arrow-right my_el-breadcrumb">
<!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
      <el-breadcrumb-item>公司介绍</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1 my_el_row"
         v-for="(iteam,index) in caseList " :key="index">
      <div class="my_div1_div1" v-html="iteam.title">
      </div>
      <div class="my_div1_div2" style="background-size: contain"
           v-lazy:background-image="iteam.picture">
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import menu from "@/utils/menu.css"
export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: {},
    }
  }, created() {
    this.$http.post('CompanyIntroduceList').then(response => {
      this.caseList = response.data;
    })
  },
  computed: {},
  mounted() {
  }, methods: {}
};
</script>

<style scoped lang="scss">

.my-menu {
    margin-top: 0px;
  padding: 0rem 5.3rem;
.el-breadcrumb {
  line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding:1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
  margin-left: 0;

}

.my_div1 {
    width: 100%;
    padding-top: 1.875rem;
    display: -ms-flexbox;
    display: flex;
    /*height: 114px;*/

    margin-left: 0rem;
    min-height: 40rem;
}

}

::v-deep.el-breadcrumb>span{
  display: flex;
}
::v-deep.el-breadcrumb>span>i{
  padding: 1px 0 0 0;
}

.my_el_row{
  width: 100%;
}
.my_div1_div1 {
  //border:#000000 solid;
  width: 50%;
  // height: 80%;
  float: left;
  //float: left;
  text-align: justify;
  padding-right: 10px;
  // padding-top: 10px;

  p {
    //font-size:20px;
    text-align: left;
    text-indent: 100px !important;
  }
}

.my_div1_div2 {
  //border: #00ff78 solid;
  width: 50%;
  float: right;
  margin: 0px;
  // background-image: url(../../assets/img/jianjietopmin.jpg);
  background-repeat: no-repeat;

}


</style>
