<template>
  <div class="companyNews my_breadcrumb_1">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
      <!--      <el-breadcrumb-item>新闻动态</el-breadcrumb-item>-->
      <el-breadcrumb-item>行业新闻</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="margin-top: 1rem; min-height: 500px; ">
      <el-card :body-style="{ padding: '0px' }"   v-for="(item, index) in caseList" style="margin-top: 1rem"
               :key="index">
        <div style="display: flex;flex-direction: row;align-items: center; justify-content: space-between;width: 100%;" @click="toSub(item)">
          <div style="width: 20%;">
            <img
              :src="item.newsPicture"
              class="image"
              style="width: 100%;height: 100%; background-size: 100% 100%"
            />
          </div>
          <div style="width: 80%; display: flex;flex-direction: column;padding: 1rem 1rem">
            <div style="padding-left: 1rem;font-size: 1.5rem;font-weight: 600;">
              {{item.newsTitle}}
            </div>
            <div style=";display: flex;flex-direction: column;justify-content: space-between;height: 9rem">
              <div  class="time text-ellipsis" v-html="item.newsText"></div>
              <div style="padding-left: 1rem;margin-top: 0.5rem">{{item.createTime}}</div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!--    <div class="my_div1">-->
    <!--      <el-row class="my_el_row">-->
    <!--        <el-col-->
    <!--          :span="8"-->
    <!--          @click.native="toSub(item)"-->
    <!--          v-for="(item, index) in caseList"-->
    <!--          :key="index"-->
    <!--          style="width: 100%; margin-bottom: 20px;" class="my_el_row_col">-->

    <!--          <el-card :body-style="{ padding: '0px' }" style="">-->
    <!--            &lt;!&ndash;            <div style="height:900px;background-size:100% 100% !important;background-repeat: no-repeat" class="swiper-img my_height"&ndash;&gt;-->
    <!--            &lt;!&ndash;                 v-lazy:background-image="iteam.picture"></div>&ndash;&gt;-->
    <!--           <div style="display: flex;flex-direction: row;align-items: center;">-->
    <!--            <div>-->
    <!--              <img-->
    <!--                :src="item.newsPicture"-->

    <!--                class="image"-->
    <!--                style="width: 300px;background-size: cover"-->
    <!--              />-->
    <!--            </div>-->
    <!--              <div style="padding:0 14px;">-->
    <!--              <a-->
    <!--                style="padding-left: 20px;font-size: 1.5rem;font-weight: 600"-->
    <!--                class="my_span"-->
    <!--              >{{ item.newsTitle }}-->
    <!--              </a>-->
    <!--              <div class="bottom clearfix" style="padding-top: 0px;width: 90%;">-->
    <!--                &lt;!&ndash; <time style="padding-left: 20px;font-size: 20px" class="time">{{ item.newsText }}</time> &ndash;&gt;-->
    <!--                <div-->
    <!--                  style="padding-left:1.7rem;"-->
    <!--                  class="time text-ellipsis"-->
    <!--                  v-text="$options.filters.msg(item.newsText)"-->
    <!--                ></div>-->
    <!--              </div>-->
    <!--                <div  style="float: right;margin-top: 1rem;margin-right: 0.8rem;">{{ item.createTime }}</div>-->
    <!--            </div>-->
    <!--           </div>-->
    <!--          </el-card>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->
    <div style="padding-left: 4rem;">
      <!-- 分页组件 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 100]"
        :page-size="pageSize"
        :total="total"
        layout="total, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: [],
      total: 0,
      currentPage: 1,
      pageSize: 5, // 每页显示条数
    };
  },
  filters: {
    msg: function (msg) {
      let msg1 = msg.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
        .replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '')
        .replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ')
        .replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '')
        .replace(/&amp;/g, "&").replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#39;/g, "\'")
        .replace(/&quot;/g, "\"")
        .replace(/<\/?.+?>/g, "")
      return msg1;
    }
  },
  created() {
    this.getList(1);

    // axios.post('http://116.205.170.48:8081/api/ago/companyNewsList')
    //     .then(response => {
    //       this.caseList = response.data.rows;
    //       // console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {
  },
  methods: {
    toSub(item) {
      localStorage.setItem('item', JSON.stringify(item));
      // console.log(item)
      this.$router.push({path: "/CompanyNewsLevel", query: { type: '行业新闻',}});
    },
    // 页面大小改变时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // 重新加载数据
      this.getList(val)
    },
    // 当前页改变时触发
    handleCurrentChange(val) {
      this.currentPage = val;
      // 重新加载数据
      this.getList(val)
    },
    getList(num1) {
      var params = new URLSearchParams();
      params.append('pageNum', num1);
      this.$http.get("IndustryNewsList?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        console.log(response)
        this.caseList = response.data.rows;
        this.total = response.data.total;
      });
    },
  },
};
</script>

<style scoped lang="scss">

.el-pagination {
  display: flex;
  justify-content: center;
}

.companyNews {
  margin-top:0px;
  padding: 0rem 5.3rem;

  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding:1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    width: 100%;
    height: auto;
    margin: 10px;
    //margin-top: 90px;
    margin-left: 0rem;
    min-height: 500px;
  }
}

::v-deep.el-breadcrumb>span{
  display: flex;
}
::v-deep.el-breadcrumb>span>i{
  padding: 1px 0 0 0;
}

.time {
  padding-top:1rem;
  font-size: 0.99rem !important;
  color: #999;
}

.bottom {
  margin-top: 13px;
  //line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.my_span {
  cursor: pointer;
  height: 3.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 86rem;
  margin-bottom: 1.85rem;
}

.text-ellipsis {
  color: black;
  padding-left: 1rem;
  font-size: 20px;
  text-align: justify;
  width: 100%;
  // height: 14.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
</style>

