<template>
  <div class="RecruitingTalents my_breadcrumb_1">
    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">
<!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>招贤纳士</el-breadcrumb-item>-->
      <el-breadcrumb-item>社会招聘</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1">
      <el-carousel
        style="margin-bottom: 15px"
        :interval="5000"
        arrow="never"
        indicator-position="none"
        height="24rem"
        autoplay="false"
      >
        <el-carousel-item
          v-for="(item, index) in caseList"
          :key="index"
          style="height: 24rem !important"
        >
          <!-- <h3>{{ item.value5 }}</h3> -->
          <div
            style="
              height: 100%;
              width: 100%;
             background-size: 100% 100%;
              background-repeat: no-repeat;
            background-position: center;
              "
            class="swiper-img my_height"
            v-lazy:background-image="item.photo"
          ></div>
        </el-carousel-item>
      </el-carousel>

      <el-table
        :data="tableData"
        border
        stripe
        style="min-height: 0 !important; width: 100%"
      >
        <el-table-column label="岗位名称" prop="jobTitle"></el-table-column>
        <el-table-column label="事业群" prop="careerGroup"></el-table-column>
        <el-table-column label="工作地址" prop="workAddress"></el-table-column>
        <el-table-column label="招聘人数" prop="numberPeople"></el-table-column>
        <el-table-column type="expand" width="90px" label="查看详情">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="岗位职责：">
                <div v-html="props.row.details"></div>
              </el-form-item>
              <el-form-item label="任职要求：">
                <span v-html="props.row.remark"></span>
              </el-form-item>
              <el-form-item label="联系方式：">
                <span>{{ props.row.value2 }}</span
                >&nbsp;&nbsp;
                <span>{{ props.row.value3 }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>
import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      caseList: [],
      tableData: [],
    };
  },
  created() {
    this.$http.post("honorsList").then((response) => {
      // console.log(response.data.rows);
      this.caseList = response.data.rows;
    });
    this.$http.post("talentsList").then((response) => {
      // console.log(response.data.rows)
      this.tableData = response.data.rows;
    });

    // axios.post('http://116.205.170.48:8081/api/ago/talentsList')
    //     .then(response => {
    //       this.tableData = response.data.rows;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {},
};
</script>


<style scoped lang="scss">
.RecruitingTalents {
  margin-top: 0px;
  padding: 0rem 5.3rem;
  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding:1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    height: auto;
   margin-top: 10px;
    //margin-top: 90px;

    width: 100%;
  }
}

::v-deep.el-breadcrumb>span{
  display: flex;
}
::v-deep.el-breadcrumb>span>i{
  padding: 1px 0 0 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

// .demo-table-expand {
//   font-size: 0;
// }

// .demo-table-expand label {
//   width: 20px;
//   color: #99a9bf;
//   font-size:17px !important;
// }

// .demo-table-expand .el-form-item {
//   margin-right: 0;
//   margin-bottom: 0;
//   width: 50%;
// }

// .aaaaaaaa{
//   font-size: 17px;
// }
</style>
