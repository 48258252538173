<template>
  <div class="TeamIntroduction my-menu">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
      <!--      <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
      <el-breadcrumb-item>团队介绍</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1 container">
      <div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: space-between;row-gap: 2rem">
        <div   @click="toSub(item)"
             v-for="(item, index) in caseList"
             :key="index">
          <img style="width: 20rem;height: 25rem" :src="item.photo" class="image"/>
        </div>
        <!--        <el-row class="my_el_row">-->
        <!--          <el-col-->
        <!--            :span="8"-->
        <!--            @click.native="toSub(item)"-->
        <!--            v-for="(item, index) in caseL ist"-->
        <!--            :key="index"-->
        <!--            class="my_span"-->
        <!--            :offset="index > 0 ? 2 : 0"-->
        <!--            style="width: 18%; height: 380px !important;margin: 0px;"-->
        <!--          >-->
        <!--            <el-card-->
        <!--              :body-style="{ padding: '0px', height: '70%' }"-->
        <!--              style="height: 100% !important;"-->
        <!--            >-->
        <!--              <img :src="item.photo" class="image"/>-->
        <!--            </el-card>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import menu from "@/utils/menu.css"

export default {
  data() {
    return {
      currentDate: new Date(),
      caseList: [],
      dialogVisible: false,
    };
  },
  created() {
    this.$http.post("introductionList").then((response) => {
      this.caseList = response.data.rows;
    });

    // axios.post('http://116.205.170.48:8081/api/ago/introductionList')
    //     .then(response => {
    //       this.caseList = response.data.rows;
    //       // console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  methods: {
    toSub(item) {
      localStorage.setItem('item', JSON.stringify(item));
      // console.log(item)
      this.$router.push({
        path: "/TeamIntroductionLevel",
        // query: {item: item},
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {
        });
    },
  },
};
</script>


<style scoped lang="scss">
.my-menu {
  width: 100%;
  margin-top: 0px;
  padding: 0rem 5.3rem;

  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    width: 100%;
    padding-top: 1.875rem;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0;
    /*height: 114px;*/
    min-height: 40rem;
  }

}

.container {
  //width: 100%;
  //margin-left: 0rem;
   //width: 95.6%;
}

.content {


}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  //line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.my_span {
  cursor: pointer;
  // box-shadow: 10px 10px 10px rgba(104, 103, 103, 0.6);
}

.image_p {
  font-size: 20px;
  text-align: center;
  // background-color: #928e8e;
}

.my_el_row {
  display: flex;
  justify-content: space-between;
}
</style>
