<template>
  <div class="CloudBasedSolutions my_breadcrumb_1" style="height: 100%;position: relative">
    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">
      <!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
      <!--      <el-breadcrumb-item>解决方案</el-breadcrumb-item>-->
      <el-breadcrumb-item>智能设备</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="my_div1">
          <p>云上方案</p>
      <div
        v-for="(item, index) in caseList"
        :key="index"
        class="my_div11"
        style="border-radius: 36px"
      >
        <el-row :gutter="20" v-if="(index + 1) % 2 == 1">
          <el-col :span="16">
            <div
              class="grid-content"
              style="text-align: left; padding-left: 20px"
              v-html="item.content"
            ></div>
          </el-col>
          <el-col :span="8">
            <div
              class="grid-content"
              style="background-size:100% 100% !important;background-repeat: no-repeat"
              v-lazy:background-image="item.picture"
            ></div>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="8">
            <div
              class="grid-content"
              v-lazy:background-image="item.picture"
            ></div>
          </el-col>
          <el-col :span="16">
            <div
              class="grid-content"
              style="text-align: left; padding-left: 20px"
              v-html="item.content"
            ></div>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div v-for="(list,index) in caseList" :key="index" style="margin-top: 10px;"  class="my_div1">
      <!--      <h2 style="text-align: center;">{{ list.title }}</h2>-->
      <div style="padding: 0 3.4rem;user-select: none" v-html="list.content"></div>
    </div>
    <!--    <div class="pagin">-->
    <!--      &lt;!&ndash; 分页组件 &ndash;&gt;-->
    <!--      <el-pagination-->
    <!--        @size-change="handleSizeChange"-->
    <!--        @current-change="handleCurrentChange"-->
    <!--        :current-page="currentPage"-->
    <!--        :page-sizes="[5, 10, 15, 100]"-->
    <!--        :page-size="pageSize"-->
    <!--        :total="total"-->
    <!--        layout="total, prev, pager, next, jumper">-->
    <!--      </el-pagination>-->
    <!--    </div>-->
  </div>
</template>


<script>
import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
export default {
  data() {
    return {
      currentDate: new Date(),
      total: 0,
      currentPage: 1,
      pageSize: 1, // 每页显示条数
      // currentDate: new Date(),
      caseList: [],
    };
  },
  created() {
    this.getList(1)
    // this.$http.post("LocalSolutionList").then((response) => {
    //   this.caseList = response.data.data;
    // console.log(this.caseList)
    // });
    // axios.post('http://116.205.170.48:8081/api/ago/CompanyIntroduceList')
    //     .then(response => {
    //       this.caseList = response.data;
    //       console.log(this.caseList)
    //       // this.loading = false;
    //       // this.userData = response.data;
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error);
    //     });
  },
  computed: {},
  mounted() {
  },
  methods: {
    // 页面大小改变时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // 重新加载数据
      this.getList(val)
    },
    // 当前页改变时触发
    handleCurrentChange(val) {
      this.currentPage = val;
      // 重新加载数据
      this.getList(val)
    },
    getList(num1) {
      this.$http.get("SmartDevices?pageNum=" + num1 + "&pageSize=" + this.pageSize + "").then((response) => {
        this.caseList = response.data.rows;
        this.total = response.data.total;
      });
    },
  },
};
</script>


<style scoped lang="scss">
.pagin {
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
}

//
.CloudBasedSolutions {
  margin-top: 0px;
  padding: 0rem 5.3rem;

  .el-breadcrumb {
    line-height: normal !important;
    width: 100%;
    font-size: 1.25rem;
    padding: 1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    height: auto;
    //margin: 1rem 1rem 3rem 1rem;
  }
}

::v-deep.el-breadcrumb > span {
  display: flex;
}

::v-deep.el-breadcrumb > span > i {
  padding: 1px 0 0 0;
}

.el-row {
  border-radius: 36px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  //   border-radius: 36px;
  min-height: 350px;
  border-radius: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.my_div11 {
  width: 98%;
  margin-bottom: 50px;
  box-shadow: 10px 10px 10px rgba(104, 103, 103, 0.6);
}

.my_div11:hover {
  border-radius: 36px;
  /* 阴影水平向右偏移10px，垂直向下偏移10px 边缘模糊半径10px */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);
}
</style>
