<template>
    <div class="HonorsLevel my_breadcrumb_1" className="TeamIntroductionLevel">
      <el-breadcrumb separator-class="el-icon-arrow-right">
<!--        <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
        <el-breadcrumb-item>资质荣誉详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="my_div1">
        <!-- 使用 router-link 组件包裹 i 标签实现跳转 -->
  <!--      <router-link to="/QualificationsAndHonors">-->
  <!--        <i style="font-size: 40px;float: right;display: flex;margin-right: 200px" class="el-icon-close my_span"></i>-->
  <!--      </router-link>-->
  <!--      <i style="font-size: 40px;float: right;display: flex;margin-right: 200px" class="my_span el-icon-close" @click="handleQuery()" ></i>-->
        <el-row class="my_el_row" style="width: 97%;border-radius: 36px;">
          <el-col :span="8" v-for="(item, index) in caseList" :key="index" :offset="index > 0 ? 2 : 0"
                  style="width:100%;height:100%;margin: 1%;border-radius: 36px;box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.6);">
            <el-card :body-style="{ padding: '0px' }" style="border-radius: 36px;">
              <img :src="item.photo" class="image" style="width: 40%;height: 400px;float: left">
              <div style="padding: 14px;height: 300px;border-radius: 36px;">
                <span style="padding-left: 20px">{{ item.introduce }}</span>
                <div class="bottom clearfix">
  <!--                <time style="padding-left: 20px" class="time">{{ item.introduce }}</time>-->
                  <!--                <el-button type="text" class="button">操作按钮</el-button>-->
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

  </template>


  <script>

  import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
  export default {
    data() {
      return {
        currentDate: new Date(),
        caseList: [],
        dialogVisible: false,
      }
    },
    created() {
      // axios.post('http://116.205.170.48:8081/api/ago/introductionList')
      //     .then(response => {
      //       this.caseList = response.data.rows;
      //       // console.log(this.caseList)
      //       // this.loading = false;
      //       // this.userData = response.data;
      //     })
      //     .catch(error => {
      //       console.error('There was an error!', error);
      //     });
    },
    mounted() {
      this.caseList.push(this.$route.query.item)
      // console.log(this.caseList);
    },
    methods: {
      handleQuery(){
        // alert("213")
        // this.$router.push('/TeamIntroduction');
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
            .then(_ => {
              done();
            })
            .catch(_ => {
            });
      }
    }
  };
  </script>


  <style scoped lang="scss">
  .HonorsLevel {
   //   height: 540px;
   margin-top: 10px;
  .el-breadcrumb {
    line-height: normal !important;
    width: 95%;
    font-size: 1.25rem;
    padding-bottom: 1.25rem;
    padding-top: 1.2rem;
    /* padding-left: 3.2rem; */
    border-bottom: 1px solid #928e8e;
    position: relative;
    top: 25%;
    // left: 45px;
    left: 3.8125rem;
    // left: 155px;
  }

  .my_div1 {
    height: 650px;
    margin: 10px;
    margin-top: 90px;
    margin-left: 140px;
    border-radius: 36px;
  }
  }


  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    //line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .my_span {
    cursor: pointer;
    height: 40px;
  }


  .my_el_row{
    border-radius: 36px;
  }
  </style>
