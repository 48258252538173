<template>
  <div id="app">
    <header>
      <header-vue></header-vue>
    </header>
    <main>
      <div class="div">
        <router-view />
      </div>
    </main>
    <footer>
      <div>
        <footer-vue></footer-vue>
      </div>
    </footer>
  </div>
</template>

<script>
import headerVue from "./views/header.vue";
import footerVue from "./views/footer.vue";
export default {
  name: "app",
  components: {
    headerVue,
    footerVue,
  },
  created() {
  },
  mounted() {
// this.bodyScale();
// window.addEventListener("resize",this.bodyScale,false);
  },
  methods: {
    // bodyScale(){
    //   var deviceWidth = document.documentElement.clientWidth;
    //   var scale = deviceWidth / 1920;
    //   document.documentElement.style.zoom = scale;
    // }
  },

};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100vh;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

 .el-menu--popup{
   min-width: 9.3rem !important;
 }


 .el-menu--popup > li{
  //  text-align: center;
  padding-left: 1.8rem !important;
  font-weight: 900;
 }



footer {

  background: #ffffff;
  // flex: 1; /* All elements will take equal space */

}
main {
  flex: 1;
  display: flex;
}

// .Myfooter1{
  // margin-top: 3rem;
// }

aside {
  width: 200px;
  line-height: 200px;
  background: #f0f0f0;
}

article {
  flex: 1;
  overflow-y: scroll;
  line-height: 1.5;
  background: #f8f8f8;
}

//@media screen and (max-width: 768px) {
//  main {
//    flex-direction: column;
//  }
//
//  aside,
//  article {
//    width: 100%;
//  }
//}

.div {
  width: 100%;
  min-height: 10rem;
}
.Myfooter {
  width: 100%;
  height: 180px;
  //position: fixed;
  left:0px;
  // display: flex;
  flex: 1;
}
</style>
