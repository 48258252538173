<template>
  <div class="ContactUs">
    <el-breadcrumb separator-class="el-icon-arrow-right my_el-breadcrumb">
<!--      <el-breadcrumb-item>首页</el-breadcrumb-item>-->
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="my_div1 my_el_row">
      <el-row :gutter="20" class="" style="height: 100%">
        <el-col :span="16" style="height: 100%">
          <div class="grid-content" style="height: 100%">
            <el-card style="height: 70%">
              <div slot="header" class="clearfix">
                <h1>{{ caseList.companyName }}</h1>
                <!--            <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
              </div>
              <div>
                <div class="text item">
                  <i class="el-icon-user"></i>&nbsp;&nbsp;市场：{{
                    caseList.name
                  }}
                </div>
                <div class="text item">
                  <i class="el-icon-phone-outline"></i>&nbsp;&nbsp;电话：{{
                    caseList.phone
                  }}
                </div>
                <div class="text item">
                  <i class="el-icon-message"></i>&nbsp;&nbsp;邮箱：{{
                    caseList.email
                  }}
                </div>
                <div class="text item">
                  <i class="el-icon-location-outline"></i>&nbsp;&nbsp;地址：{{
                    caseList.address
                  }}
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div class="map-container">
              <el-amap id="container"></el-amap>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import '@/assets/css/my_breadcrumb.css';
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'

const amapManager = new AMapManager()
export default {
  // name: 'amap',
  // props: {
  //   center: {
  //     type: Array,
  //     default: () => [116.39732, 39.917797] // 西湖风景区的经纬度
  //   },+
  // },
  data () {
    const self = this
    return {
      currentDate: new Date(),
      caseList: {},
      plugin: {
        showMaker: true
      },
      markers: []
    }
  },
  created () {
    this.$http.post('gitContactUs').then((response) => {
      this.caseList = response.data.data
    })
    lazyAMapApiLoaderInstance.load().then(() => {
      this.initMap()
    })
  },
  computed: {},
  mounted () {},
  methods: {
    // 给地图添加标记
    initMap () {
      this.map = new AMap.Map('container', {
        center: [106.559961, 29.534752],
        pitch: 50, // 俯仰角度
        viewMode: 'D', // 使用3D视图
        zoom: 16, // 级别
        zoomControl: true
      })
      const marker = new AMap.Marker({
        position: new AMap.LngLat(106.559961, 29.534752), // 标记位置
        map: this.map // 指定将标记添加到哪个地图实例中
      })
      marker.setMap(this.map)
    },
    toSub (item) {
      // console.log(item)
      this.$router.push({ path: '/CompanyNewsLevel', query: { item: item } })
    }
  }
}
</script>

  <style scoped lang="scss">
.map-container {
  height: 350px;
}

.map {
  height: 100%;
}

.ContactUs {
  margin-top: 0px;
  padding: 0rem 5.3rem;
  .el-breadcrumb {
    line-height: normal !important;
    text-align: center;
    width: 100%;
    font-size: 1.25rem;
    padding:1.25rem 3.4rem;
    border-bottom: 1px solid #928e8e;
    display: flex;
    margin-left: 0;
  }

  .my_div1 {
    height: auto;
    margin-top: 10px;
    //margin-top: 90px;
    width: 100%;
  }

  .my_el_row {
    // margin-left: 6%;
    height: 500px;
  }
}
::v-deep.el-breadcrumb>span{
  display: flex;
}
::v-deep.el-breadcrumb>span>i{
  padding: 1px 0 0 0;
}
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
  height: 500px;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text {
  font-size: 30px;
  //margin-left: -1000px;
  text-align: left;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>
