import Vue from 'vue'
import VueRouter from 'vue-router'
import indexHome from '../views/index.vue'
import ContactUs from '../views/ContactUs.vue'
import CompanyIntroduction from '../views/CompanyIntroduction.vue'
import TeamIntroduction from '../views/TeamIntroduction.vue'
import TeamIntroductionLevel from '../views/TeamIntroductionLevel.vue'
import QualificationsAndHonors from '../views/QualificationsAndHonors.vue'
import HonorsLevel from '../views/HonorsLevel.vue'
import CloudBasedSolutions from '../views/CloudBasedSolutions.vue'
import LocalPlan from '../views/LocalPlan.vue'
import CompanyNews from '../views/CompanyNews.vue'
import CompanyNewsLevel from '../views/CompanyNewsLevel.vue'
import IndustryNews from '../views/IndustryNews.vue'
import RecruitingTalents from '../views/RecruitingTalents.vue'
import WMSView from '../views/WMSView.vue'
import SmartDevices from '../views/SmartDevices.vue'
import RecruitingTalentsCampus from '../views/RecruitingTalentsCampus.vue'
import header from '../views/header.vue'
import footer from '../views/footer.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: indexHome
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/CompanyIntroduction',
    name: 'CompanyIntroduction',
    component: CompanyIntroduction
  },
  {
    path: '/TeamIntroduction',
    name: 'TeamIntroduction',
    component: TeamIntroduction
  },
  {
    path: '/TeamIntroductionLevel',
    name: 'TeamIntroductionLevel',
    component: TeamIntroductionLevel
  },
  {
    path: '/QualificationsAndHonors',
    name: 'QualificationsAndHonors',
    component: QualificationsAndHonors
  },
  {
    path: '/HonorsLevel',
    name: 'HonorsLevel',
    component: HonorsLevel
  },
  {
    path: '/CloudBasedSolutions',
    name: 'CloudBasedSolutions',
    component: CloudBasedSolutions
  }, {
    path: '/LocalPlan',
    name: 'LocalPlan',
    component: LocalPlan
  },
  {
    path: '/CompanyNews',
    name: 'CompanyNews',
    component: CompanyNews
  },
  {
    path: '/CompanyNewsLevel',
    name: 'CompanyNewsLevel',
    component: CompanyNewsLevel
  },
  {
    path: '/IndustryNews',
    name: 'IndustryNews',
    component: IndustryNews
  },
  {
    path: '/RecruitingTalents',
    name: 'RecruitingTalents',
    component: RecruitingTalents
  },
  {
    path: '/WMSView',
    name: 'WMSView',
    component: WMSView
  },
  {
    path: '/SmartDevices',
    name: 'SmartDevices',
    component: SmartDevices
  },
  {
    path: '/RecruitingTalentsCampus',
    name: 'RecruitingTalentsCampus',
    component: RecruitingTalentsCampus
  },
  { path: '/baidu', redirect: 'https://www.baidu.com' },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
