<template>

  <div class="footer">
<!--    <div class="footer_1">-->
<!--      <div style="width: 310px;line-height: 20px;font-size: 13px;float: left;">-->
<!--      </div>-->
<!--    </div>-->
    <div class="footer_2">
      <div>
        <el-button  @click="buttonHidden"  type="info" class="footer_3">
          <div style="writing-mode: horizontal-tb">
            <span>联系我们</span>
<!--            <span style="writing-mode: vertical-lr">{{ arrow }}</span>-->
          </div>
        </el-button>
      </div>
    <div  class="footer_4">
      <div class="footer_5">
        <div class="footer_6">
          <a class="footer_7" href="http://wpa.qq.com/msgrd?v=3&uin=759821243&site=qq&menu=yes" target="_blank">
          <img src="../assets/img/QQ2.png" style="width: 1.2rem;">
          <div class="footer_8">售前咨询</div>
          </a>
        </div>
        <div class="footer_6">
          <a class="footer_7" target="_blank"  href="http://wpa.qq.com/msgrd?v=3&uin=759821243&site=qq&menu=yes">
          <img src="../assets/img/QQ2.png" style="width: 1.2rem">
          <div class="footer_8">售后咨询</div>
          </a>
        </div>
        <div class="footer_6">
          <a class="footer_7" target="_blank"  href="http://wpa.qq.com/msgrd?v=3&uin=2970201650&site=qq&menu=yes">
            <img src="../assets/img/QQ2.png" style="width: 1.2rem">
            <div class="footer_8">技术支持</div>
          </a>
        </div>
      </div>
<!--      <el-drawer class="drawer-modify"-->
<!--                 size="100%"-->
<!--                 @close="drawerShow = true"-->
<!--                 :wrapperClosable="false"-->
<!--                 append-to-body-->
<!--                 :modal="false"-->
<!--                 :visible.sync="drawer">-->
<!--        <div class="drawe-inner">-->
<!--          <div style="padding: 0 0.5rem">-->
<!--            <div-->
<!--              style="display: flex;justify-content: center;align-items: center;border-bottom: #99a9bf 0.1rem solid;padding-bottom: 0.5rem">-->
<!--              <img src="../assets/img/QQ2.png" style="width: 1rem;">-->
<!--              <span style="font-size: 0.7rem;margin-left: 0.2rem">售前咨询</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <br>-->
<!--          <div style="padding: 0 0.5rem">-->
<!--            <div-->
<!--              style="display: flex;justify-content: center;align-items: center;border-bottom: #99a9bf 0.1rem solid;padding-bottom: 0.5rem">-->
<!--              <img src="../assets/img/QQ2.png" style="width: 1rem">-->
<!--              <span style="font-size: 0.7rem;margin-left: 0.2rem">售后咨询</span>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </el-drawer>-->
    </div>
    </div>
<!--    <div  v-if="this.drawerShow" style="border-radius: 1rem 1rem 1rem 1rem;position: fixed; width: 500px;background-color: #ffffff;top: 2rem;z-index: 999;top: 25%;left: 35%;">-->
<!--      <el-card style="border-radius: 1rem 1rem 0 0rem;">-->
<!--        <div style="border-bottom: #409EFF 0.1rem solid;background-color: #409EFF;">-->
<!--          <div style="display: flex;flex-direction: column;padding: 0.5rem;color: #ffffff;gap: 0.5rem">-->
<!--            <div style="display: flex;flex-direction: row;justify-content: space-between">-->
<!--              <span>在线咨询</span>-->
<!--              <i @click="drawerShow = false" class="el-icon-close"></i>-->
<!--            </div>-->
<!--            <span>15258458965</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="width: 100%;height: 300px;">-->
<!--          <ul class="infinite-list" v-infinite-scroll="load" style="height: 300px;width: 100%; overflow:auto">-->
<!--            <li v-for="i in count" class="infinite-list-item">{{ i }}</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </el-card>-->
<!--      <div>-->
<!--          <el-input style=""-->
<!--                    type="textarea"-->
<!--                    :rows="5"-->
<!--                    placeholder="请输入内容"-->
<!--                    v-model="inputText">-->
<!--          </el-input>-->
<!--&lt;!&ndash;        <button>dsfsd</button>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->


    <div class="footer-content">
      <div class="footer-content my_footer-content">
        <ul class="content-nav">
          <div style="display: flex;flex-direction: row">
            <li>
              <p>关于我们</p>
              <router-link to="/CompanyIntroduction">
                <span>公司介绍</span>
              </router-link>
              <router-link to="/TeamIntroduction">
                <span>团队介绍</span>
              </router-link>
            </li>
            <li>
              <p>解决方案</p>
              <router-link to="/CloudBasedSolutions">
                <span>汽车行业</span>
              </router-link>
              <router-link to="/LocalPlan">
                <span>电子电路行业</span>
              </router-link>
              <!--          <router-link to="/WMSView">-->
              <!--            <span>WMS</span>-->
              <!--          </router-link>-->
              <router-link to="/SmartDevices">
                <span>智能设备</span>
              </router-link>
            </li>
            <li>
              <p>新闻动态</p>
              <router-link to="/CompanyNews">
                <span>公司新闻</span>
              </router-link>
              <router-link to="/IndustryNews">
                <span>行业新闻</span>
              </router-link>
            </li>
            <li>
              <p>招贤纳士</p>
              <router-link to="/RecruitingTalents">
                <span>社会招聘</span>
              </router-link>
              <router-link to="/RecruitingTalentsCampus">
                <span>校园招聘</span>
              </router-link>
            </li>
            <li>
              <!--          <router-link class="my-router-link" to="/ContactUs">-->
              <p>联系我们</p>
              <!--          </router-link>-->
              <router-link to="/ContactUs">
                <span class="">市场：叶先生</span>
              </router-link>
              <router-link to="/ContactUs">
                <span>邮箱：baisp01@163.com</span>
              </router-link>
              <router-link to="/ContactUs">
                <span>电话：15202352413</span>
              </router-link>
              <!--          <router-link to="/ContactUs">-->
              <!--            <span>地址：重庆市南岸区铜元局街道亚太路1号1幢5层19号</span>-->
              <!--          </router-link>-->
            </li>
          </div>
          <div>
            <li style="margin-right: 10px">
              <p>友情链接</p>
              <router-link to="/ContactUs">
                <span @click="dianzi()">中国电子电路行业协会</span>
              </router-link>
              <router-link to="/ContactUs">
                <span @click="qiche()">中国汽车工业协会</span>
              </router-link>
              <router-link to="/ContactUs">
                <span @click="car()">国际汽车特别行动小组</span>
              </router-link>
            </li>
          </div>
        </ul>
        <!--      <div>-->
        <!--        <span class="my_p">友情链接</span>-->
        <!--        &lt;!&ndash; <router-link to="/baidu"> &ndash;&gt;-->
        <!--        <p @click="dianzi()">中国电子电路行业协会</p>-->
        <!--        &lt;!&ndash; </router-link> &ndash;&gt;-->
        <!--        <p @click="qiche()">中国汽车工业协会</p>-->
        <!--      </div>-->
        <!--          <img src="./assets/img/ercode.png" alt/>-->
        <div class="public-account" >
          <div  class="imgs">
            <!-- <img src="./assets/img/ercode.png" alt=""> -->
            <img style="width: 5rem" src="@/assets/img/sph.png" >
            <span style="margin-top: 1rem;">视频号</span>
          </div>
          <div class="imgs">
            <!-- <img src="./assets/img/ercode.png" alt=""> -->
            <img style="width: 5rem;" src="@/assets/image.png">
            <span style="margin-top: 1rem">公众号</span>
          </div>
        </div>
      </div>
      <!-- <div style="border: 1px red solid;width: 130px;height: 130px;float: right;margin-left: 200px;"></div> -->
      <div class="copyright text-center">
        <div style="width: 100%;border-top: #ffffff 1px solid;display: flex;align-items: center;">佰世普信息技术 (重庆) 有限公司版权所有&nbsp;2024&nbsp;Copyright&nbsp;&copy; <a class="footer_9" target="_blank" href="https://www.baisptech.com">www.baisptech.com</a>  ∣ <a class="footer_9" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2024043663号-1</a> ∣ <img style="width: 1rem;" src="@/assets/img/police.png">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50010802006376" rel="noreferrer" target="_blank" class="footer_9">&nbsp;渝公网安备50010802006376</a>
        </div>
        <!-- <router-link class="my-router-link" to="/ContactUs">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;备案号：</span>
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputText:'',
      drawer: true,
      drawerShow: false,
      arrow:'>',
      isRetract:'false',
      count: [1]
    }
  },
  methods: {
    load () {
      this.count = [1,5]
    },
    dianzi() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'https://www.cpca.org.cn';
      window.open(url, '_blank');
    },
    qiche() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'http://www.caam.org.cn';
      window.open(url, '_blank');
    },
    car() {
      // alert("1212321")
      // window.location.href = 'http://www.baidu.com'
      const url = 'https://www.iatfglobaloversight.org/'
      window.open(url, '_blank')
    },
    buttonHidden() {
      this.drawerShow = !this.drawerShow
      // this.drawer = true
      // this.isRetract = !this.isRetract
      // if (this.isRetract){
      //   this.arrow = '>'
      // }else{
      //   this.arrow = '<'
      // }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  border-radius: 0 0  1rem 1rem;
}
::v-deep .el-textarea__inner:focus {
  border-color: #C0C4CC;
}
::v-deep .el-textarea__resize-handle {
  display: none;
}
.my_footer-content {
  padding: 0 4.8rem 0 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imgs {
  text-align: center;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  font-weight: 600;
  margin-top: 1rem;
  // overflow: hidden;
  //background-color: #a1c7f3;
  //background-color: #14679f;
  // position: sticky;

  ::v-deep .el-card__body{
    padding: 0;
  }
  &-content {
    background-color: rgb(214, 211, 211);
    margin: 0 auto;
    padding-top: 10px;

    // display: flex;
    // justify-content: space-between;

    .content-nav {
      display: flex;flex-direction: row;justify-content: space-between;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        //justify-content: center;
        align-items: flex-start;
        font-weight: 600;

        p {
          font-size: 18px;
          color: #000000;
          // color: #d4edff;
          padding: 10px 0;
        }
        span {
          //color: #f7f7f7;
          color: #000000;
          // font-weight: 500;
          font-size: 15px;
          padding: 5px 0;
        }
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    padding: 0 4rem 0 5.3rem;
    //background: #125688;
    line-height: 50px;
    height: 50px;
    width: 100%;
    // position: fixed;
    // bottom: 0;

    span {
      color: #000000;
      //color: #fff;
      line-height: 30px;
      font-size: 15px;
    }
  }
}
.footer_1 {
  background-color: #fff;width: 100%;height: 20px;
}
.footer_2 {
  position: fixed;top: 50%;right: 0; writing-mode: vertical-lr;z-index: 999;display: flex;flex-direction: row;
}
.footer_3{
  width: 5rem; border-bottom: none; padding: 1rem 0; border-radius: 0.2rem 0.2rem 0 0;
}
.footer_4 {
  width: 5rem;height: 10rem;border-top: none; background: #ffffff;border-radius:0 0 0.2rem 0.2rem;border: rgb(179,176,176) 0.3rem solid;
}
.footer_5 {
  width: 100%;padding: 0.1rem;display: flex;flex-direction: row;align-items: center;justify-content: center;column-gap: 0.5rem;
}
.footer_6 {
 width: 100%;height: 2rem;display: flex;flex-direction: column;align-items: center;border-bottom: #909399 0.1rem solid;padding: 0.1rem;
}
.footer_7 {
  width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center; text-align: center;
}
.footer_8 {
  writing-mode: horizontal-tb;color: rgb(0,0,255);font-size: 0.7rem;font-weight: 900;
}
.footer_9 {
  color: black;font-family: 'DengXian Light';font-weight: 700;
}
.my_span {
  cursor: pointer;
  height: 40px;
}

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 0px;
  box-sizing: border-box;
}

.router-link-active {
  text-decoration: none;
}

.my-router-link {
  .my_p {
    font-size: 20px;
    color: #addbfa;
    font-weight: 600;
    padding: 0px 0;
  }
}

a {
  text-decoration: none;
  padding: 0px 0;
  color: #f7f7f7;
  font-weight: 300;
  padding: 5px 0;
}

.public-account {
  display: flex;
  flex-direction: row;
  //margin-right: 0rem;
  justify-content: space-between;
}

//::v-deep>.el-drawer__container {
//  position: relative;
//  left: 0;
//  right: 0%;
//  top: 80%;
//  bottom: 0%;
//  height: 20%;
//  width: 100%;
//}
.drawer-modify {
  border-radius: 10px;
  top: 40%;
  height: 12rem;
  left:95.6%;
}

::v-deep .el-drawer {
  border-radius: 10px;
  background-color: #409EFF; /* 浅蓝色背景 */
}

/* 如果有需要修改遮罩层样式 */
::v-deep .el-drawer__mask {
  background-color: rgba(0, 0, 0, 1); /* 半透明黑色背景 */
}

.drawe-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
}

.divider {
  height: 0.1rem;
  width: 0.1rem;
  border-left: black 1px solid;
}
</style>
