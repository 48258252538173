<template>
    <div class="TeamIntroductionLevel my_breadcrumb_1" className="TeamIntroductionLevel ">
      <el-breadcrumb separator-class="el-icon-arrow-right">
<!--        <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>关于我们</el-breadcrumb-item>-->
        <el-breadcrumb-item>介绍详情</el-breadcrumb-item>
      </el-breadcrumb>

      <div style="display: flex;flex-direction: row;margin-top: 20px">
      <div>
        <img :src="caseList.photo" class="image" style="width: 310px;height: 413px;">
      </div>
        <div style="margin-left: 40px">
          <h2 style="font-size: 40px">{{ caseList.name }}</h2>
          <div style="margin-top: 30px" v-html="caseList.introduce"></div>
        </div>
      </div>
    </div>

  </template>


  <script>

  import axios from "axios";
//   import '@/assets/css/my_breadcrumb.css';
  export default {
    data() {
      return {
        currentDate: new Date(),
        caseList: [],
        dialogVisible: false,
      }
    },
    created() {
      // axios.post('http://116.205.170.48:8081/api/ago/introductionList')
      //     .then(response => {
      //       this.caseList = response.data.rows;
      //       // console.log(this.caseList)
      //       // this.loading = false;
      //       // this.userData = response.data;
      //     })
      //     .catch(error => {
      //       console.error('There was an error!', error);
      //     });
    },
    mounted() {
      // console.log(this.$route.query.item);

      // this.caseList=this.$route.query.item
      this.caseList = JSON.parse(localStorage.getItem('item') || '[]');

      // console.log(this.caseList);
    },
    methods: {  }
  };
  </script>


  <style scoped lang="scss">
  .TeamIntroductionLevel {
    margin-top: 0px;
    padding: 0rem 5.3rem;

    .el-breadcrumb {
    line-height: normal !important;
      width: 100%;
      font-size: 1.25rem;
      padding-bottom: 1.25rem;
      padding:1.25rem 3.4rem;
      border-bottom: 1px solid #928e8e;
      display: flex;
      margin-left: 0;
    }

   .my_div1 {
     width: 100%;
     padding-top: 1.875rem;
     display: -ms-flexbox;
     display: flex;
     /*height: 114px;*/
     min-height: 40rem;
   }
  }


  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    //line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    //display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .my_span {
    cursor: pointer;
    height: 40px;
  }

  .my_el_row{
    border-radius: 36px;
  }
  </style>
